<template>
  <div class="visibility-page">
    <div class="search-visibility-cols visibility">
      <div class="block-heading">
        <div class="title">
          Search Visibility in <dynamic-image classes="flag"
                                              :width="22"
                                              :height="16"
                                              :size="32"
                                              :country-code="currentCountry?.code"/>&nbsp;{{ currentCountry?.name }}
        </div>
        <div style="color: var(--neutral-750);">{{formatDate(getNDaysAgoDate(2))}} - {{formatDate(getNDaysAgoDate(1))}}</div>
      </div>

      <div class="visibility-col left-side">
        <div class="cols-bundle details">
          <div class="sub-col">
            <div class="table-info-block">
              <div class="details-block">
                <div class="amount">
                  <template v-if="searchVisibilityDataLoaded">
                    {{ searchVisibilityData?.total }}
                  </template>
                  <template v-else>
                    <div class="skeleton total-skeleton"></div>
                  </template>
                </div>
                <div class="title">Total Keywords</div>
              </div>
              <div class="icon-block">
                <img src="@/assets/svg/total-keywords.svg" alt="Total Keywords icon">
              </div>
            </div>
          </div>
          <div class="sub-col">
            <div class="table-info-block">
              <div class="details-block">
                <div class="amount">
                  <template v-if="searchVisibilityDataLoaded">
                    {{ searchVisibilityData?.visibility }}
                  </template>
                  <template v-else>
                    <div class="skeleton total-skeleton"></div>
                  </template>
                </div>
                <div class="title">Visibility</div>
              </div>
              <div class="icon-block">
                <img src="@/assets/svg/visibility-icon.svg" alt="Visibility icon">
              </div>
            </div>
          </div>
        </div>

        <div class="cols-bundle tables">
          <div class="sub-col">
            <div class="compare-mobile-table-block">
              <div class="heading">Keyword movements</div>
              <div class="compare-mobile-table">
                <div class="table-body">
                  <template v-if="searchVisibilityDataLoaded">
                    <div class="table-row"
                         v-for="item in searchVisibilityData.keywords_movements">
                      <div class="table-item">
                        <div class="key">{{ item.key }}</div>
                      </div>
                      <div class="table-item category">
                        <div class="dynamics-block">
                          <div class="dynamic"
                               v-if="item.dynamic !== 0"
                               :class="constructDynamicColor(item.dynamic)">
                            {{ item.dynamic > 0 ? '+' + item.dynamic : item.dynamic }}
                          </div>
                          <div class="value">
                            {{ formatAmountNumbers(item.count) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="table-row"
                         v-for="item in 4">
                      <div class="table-item">
                        <div class="skeleton small medium-skeleton-box"></div>
                      </div>
                      <div class="table-item category">
                        <div class="skeleton small very-short-skeleton-box"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-col">
            <div class="compare-mobile-table-block">
              <div class="heading">Keyword breakdown</div>
              <div class="compare-mobile-table">
                <div class="table-body">
                  <template v-if="searchVisibilityDataLoaded">
                    <div class="table-row"
                         v-for="item in searchVisibilityData.keywords_breakdown">
                      <div class="table-item">
                        <div class="key">{{ item.key }}</div>
                      </div>
                      <div class="table-item category">
                        <div class="dynamics-block">
                          <div class="dynamic"
                               v-if="item.dynamic !== 0"
                               :class="constructDynamicColor(item.dynamic)">
                            {{ item.dynamic > 0 ? '+' + item.dynamic : item.dynamic }}
                          </div>
                          <div class="value">
                            {{ formatAmountNumbers(item.count) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="table-row"
                         v-for="item in 4">
                      <div class="table-item">
                        <div class="skeleton small medium-skeleton-box"></div>
                      </div>
                      <div class="table-item category">
                        <div class="skeleton small very-short-skeleton-box"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="visibility-col right-side">
        <div class="cols-bundle">
          <div class="sub-col">
            <div class="compare-mobile-table-block">
              <div class="heading">Rank up</div>
              <div class="compare-mobile-table">
                <div class="table-body">
                  <template v-if="searchVisibilityDataLoaded">
                    <div class="table-row"
                         v-for="item in searchVisibilityData.rank_up">
                      <div class="table-item">
                        <div class="key">{{ item.keyword }}</div>
                      </div>
                      <div class="table-item category">
                        <div class="dynamics-block">
                          <div class="dynamic"
                               v-if="item.rank?.dynamic !== 0"
                               :class="constructDynamicColor(item.rank?.dynamic)">
                            {{ item.rank?.dynamic > 0 ? '+' + item.rank?.dynamic : item.rank?.dynamic }}
                          </div>
                          <div class="value">
                            {{ formatAmountNumbers(item.rank?.value) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="table-row"
                         v-for="item in 8">
                      <div class="table-item">
                        <div class="skeleton small medium-skeleton-box"></div>
                      </div>
                      <div class="table-item category">
                        <div class="skeleton small very-short-skeleton-box"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-col">
            <div class="compare-mobile-table-block">
              <div class="heading">Rank down</div>
              <div class="compare-mobile-table">
                <div class="table-body">
                  <template v-if="searchVisibilityDataLoaded">
                    <div class="table-row"
                         v-for="item in searchVisibilityData.rank_down">
                      <div class="table-item">
                        <div class="key">{{ item.keyword }}</div>
                      </div>
                      <div class="table-item category">
                        <div class="dynamics-block">
                          <div class="dynamic"
                               v-if="item.rank?.dynamic !== 0"
                               :class="constructDynamicColor(item.rank?.dynamic)">
                            {{ item.rank?.dynamic > 0 ? '+' + item.rank?.dynamic : item.rank?.dynamic }}
                          </div>
                          <div class="value">
                            {{ formatAmountNumbers(item.rank?.value) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="table-row"
                         v-for="item in 8">
                      <div class="table-item">
                        <div class="skeleton small medium-skeleton-box"></div>
                      </div>
                      <div class="table-item category">
                        <div class="skeleton small very-short-skeleton-box"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-block">
        <base-button mode="default-new"
                     :is-blank="true"
                     :link="true"
                     :to="rankingsUrl"
                     width="260px"
                     height="46px">Go to Ranking Keywords
        </base-button>
      </div>
      <div class="impressions-filter-container">
        <div class="title">
          <dynamic-image classes="flag"
                         :width="22"
                         :height="16"
                         :size="32"
                         :country-code="currentCountry?.code"/>
          Impressions Stats
        </div>
        <div class="filters-block">
          <div class="text">Country:</div>
          <country-selector ref="countrySelector"
                            v-if="countries.length > 0"
                            :all-countries="countries"
                            :selected-outer="selectedCountries"
                            @changed="changeCountries"/>
          <datepicker-with-range-old
              v-if="dateFilter"
              :to-right="true"
              :date-filter="dateFilter"
              :min-date="minDate"
              :max-date="maxDate"
              :columns="2"
              :ranges="[7, 30, 90, 180]"
              @date-filter-changed="applyDatePicker"/>
        </div>
      </div>
      <div class="impressions-chart">
        <div class="content">
          <template v-if="impressionsChartDataLoading">
            <div class="preloader-wrap">
              <preloader-table-small loader-size="100px"></preloader-table-small>
            </div>
          </template>
          <template v-else>
            <server-error v-if="showTimeoutError"
                          button-text="Contact us for help"
                          @clicked="openIntercom">
              <template v-slot:title>
                Data Overflow…
              </template>
              <template v-slot:text>
                <p>
                  Due to the large volume of data received for this application, generating the report caused an error.
                </p>
                <p>
                  <b>
                    Please decrease the time period
                    <span v-if="selectedCountries.length > 1 || selectedCountries.length === 0">
                  or reduce the number of countries
                </span>
                  </b>
                  to generate a report for this application.
                </p>
                <p>
                  <i>We are working to prevent this from happening in the future.</i>
                </p>
              </template>
            </server-error>
            <template v-else-if="impressionsChartData.length < 1">
              <div class="no-data-block">
              <span>
                <span class="logo">
                  <app-image
                      :width="28"
                      :height="28"
                      :src="this.currentApp.info?.logo"
                  />
                </span>
                <span class="app-title">{{ this.currentApp.info?.title }}</span>
                {{ noDataTitle }}
              </span>
              </div>
            </template>
            <template v-else>
              <div class="chart-container">
                <impressions-chart :chart-data="impressionsChartData"
                                   v-if="impressionsChartData.length > 0"
                                   :title="''"
                />

                <a :href="impressionsReportUrl" class="chart-url" target="_blank">Analyze in Impressions Report</a>
              </div>

            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/UI/BaseButton/index.vue";
import rankingKeywords from "@/api/modules/ranking-keywords";
import {mapGetters} from "vuex";
import {httpRequest} from "@/api";
import {getDefaultMinDate, shortUnits} from "@/utils/utils";
import {formatDate, getNDaysAgoDate} from "@/utils/utils";
import CountrySelector from "@/views/Reports/components/CountrySelector/index.vue";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import ServerError from "@/components/ServerError/index.vue";
import ImpressionsChart from "@/views/Reports/Impressions/components/ImpressionsChart/index.vue";
import Preloader from "@/components/UI/Preloader/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: 'SearchVisibilityBlock',
  props: {
    appData: Object,
  },
  data() {
    return {
      searchVisibilityDataLoaded: false,
      searchVisibilityData: {},
      dateFilter: null,
      minDate: new Date(),
      maxDate: new Date(),
      countries: [],
      selectedCountries: null,
      impressionsChartData: [],
      impressionsChartDataLoading: true,
      showTimeoutError: false,
    };
  },
  components: {
    Preloader,
    PreloaderTableSmall,
    ImpressionsChart,
    ServerError,
    AppImage,
    DatepickerWithRangeOld,
    CountrySelector,
    BaseButton
  },
  created() {
    this.minDate = getDefaultMinDate();
    this.maxDate.setDate((new Date()).getDate() - 1);
    this.setDateFilter();
  },
  async mounted() {
    this.selectedCountries = [this.currentCountry.code];
    await this.fetchSearchVisibilityBlock();
    await this.fetchCountries();
    await this.fetchImpressionsChartData();
  },
  methods: {
    formatDate,
    getNDaysAgoDate,
    async fetchSearchVisibilityBlock() {
      this.searchVisibilityDataLoaded = false;
      const result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.dashboard.SEARCH_VISIBILITY_BLOCK
          + '?country_code=' + this.currentCountry.code + '&app_id=' + this.appData.id
      );

      if (!result.hasOwnProperty('errors') && JSON.stringify(result) !== '{}' && JSON.stringify(result) !== '[]') {
        this.searchVisibilityData = {
          total: result?.total ? shortUnits(result.total, 1) : 'n/a',
          visibility: result?.visibility ? shortUnits(result?.visibility, 1) : 'n/a',
          keywords_movements: this.constructVisibilityTable(result?.keywords_movements, 'MOVEMENTS_CONST'),
          keywords_breakdown: this.constructVisibilityTable(result?.keywords_breakdown, 'BREAKDOWN_CONST'),
          rank_up: result?.rank_up?.splice(0, 8),
          rank_down: result?.rank_down?.splice(0, 8),
        }
      }

      this.searchVisibilityDataLoaded = true;
    },
    constructDynamicColor(value) {
      if (value > 0 || value === 'in') {
        return 'green';
      } else if (value < 0 || value === 'out') {
        return 'red';
      }
    },
    formatAmountNumbers(value) {
      return value?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    },
    constructVisibilityTable(obj, type) {
      let newObj = {};
      let newArr = [];
      for (const key in obj) {
        newArr.push({
          ...obj[key],
          key: this.visibilityTableKeysRename(key, type),
          value: this.visibilityTableKeysValues(key, type),
        });

        newObj[key] = {
          ...obj[key],
          key: this.visibilityTableKeysRename(key, type),
          value: this.visibilityTableKeysValues(key, type),
        };
      }

      return newArr.sort((a, b) => (a.value > b.value) ? 1 : -1);
    },
    visibilityTableKeysRename(key, type) {
      const allConsts = {
        MOVEMENTS_CONST: {
          'new': 'New',
          'increased': 'Improved',
          'decreased': 'Decreased',
          'out': 'Dropped out',
        },
        BREAKDOWN_CONST: {
          'top_1': 'Top 1',
          'top_2_5': 'Top 2-5',
          'top_6_10': 'Top 6-10',
          'top_11_20': 'Top 11-20',
        },
      }

      return allConsts[type][key];
    },
    visibilityTableKeysValues(key, type) {
      const allConsts = {
        MOVEMENTS_CONST: {
          'new': 1,
          'increased': 2,
          'decreased': 3,
          'out': 4,
        },
        BREAKDOWN_CONST: {
          'top_1': 1,
          'top_2_5': 2,
          'top_6_10': 3,
          'top_11_20': 4,
        },
      }

      return allConsts[type][key];
    },
    setDateFilter() {
      this.dateFilter = {...this.getLastDaysRange(30)};
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();

      return {
        start: nowStart.setDate(nowStart.getDate() - days - 1),
        end: nowEnd.setDate(nowEnd.getDate() - 1),
      }
    },
    applyDatePicker(event) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else {
        let tempFilterObj = {
          start: event?.start,
          end: event?.end,
        }
        this.dateFilter = {...tempFilterObj};
      }
    },
    async fetchCountries() {
      const countries = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES + this.countriesRequestUrl,
      );

      if (this.countries.length === 0 && countries instanceof Array && countries.length > 0) {
        this.countries = countries;
      }
    },
    changeCountries(codes) {
      this.selectedCountries = codes;
    },
    openIntercom() {
      Intercom('showNewMessage');
    },
    async fetchImpressionsChartData() {
      this.impressionsChartDataLoading = true;
      const url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.IMPRESSIONS_REPORT;
      const data = await httpRequest('GET', url + this.getImpressionsParams());

      if (data.chart_data !== undefined) {
        this.impressionsChartData = data.chart_data;
        this.showTimeoutError = false;
      } else if (data.status === 500 || data.status === 502) {
        this.showTimeoutError = true;
      }

      this.impressionsChartDataLoading = false;
    },
    getImpressionsParams() {
      let params = '?app_id=' + this.currentApp.id
          + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
          + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
          + '&group_by=day'
          + '&mode=keywords'
          + '&source=ranking'
          + '&show_changes=all';

      this.selectedCountries.forEach((code) => {
        params += '&country_codes[]=' + code;
      });

      return params;
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
    ]),
    rankingsUrl() {
      return rankingKeywords.rankingsLink(this.appData, this.currentCountryManual?.code ?? this.currentCountry.code);
    },
    countriesRequestUrl() {
      if (this.currentApp.id === undefined) {
        return null;
      }

      return '?app_id=' + this.currentApp.id
          + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
          + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
          + '&source=ranking'
          + '&show_changes=all';
    },
    impressionsReportUrl() {
      const route = this.$router.resolve({name: 'ImpressionsReport'});

      return route.fullPath + this.getImpressionsParams();
    },
    noDataTitle() {
      return 'doesn’t have ranking keywords with estimated impression for selected countries.';
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
  },
  watch: {
    selectedCountries(newValue, oldValue) {
      if (oldValue !== null) {
        this.fetchImpressionsChartData();
      }
    },
    dateFilter(newValue, oldValue) {
      if (oldValue !== null) {
        this.fetchImpressionsChartData();
      }
    },
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>
<style scoped>
.block-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
